.Toastify__toast-container--top-right {
  right: 11em !important;
}

.toast {
  .Toastify__toast {
    border-radius: 5px;
    border-width: 0 0 0 10px;
    border-style: solid;
    border-color: #CACFFE;
    width: 450px;
    min-height: 70px;
    top: 150px;
    padding-right: 20px;
  }

  .Toastify__toast-body {
    font-family: "Century Gothic Bold", sans-serif;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.2px;
    /* text-transform: uppercase; */
    color: #808BF8;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
  }
}