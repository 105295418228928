@media screen and (min-width: 0px) {
  .MainAdmin {
    background-color: rgb(227, 230, 255, 0.5);
    width: 100%;
    height: calc(100% - 110px);
    position: fixed;
    right: 0;
    top: 110px;
    flex-grow: 2;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #CACFFE;
    }

    &::-webkit-scrollbar-thumb {
      background: #A4ADFF;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #96A0FF;
    }
  }

  .BoxContainer {
    width: 100%;
    height: auto;
    margin: 75px 0 25px 0;
    background-color: #FFFFFF;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  }

  .MainUser {
    background-color: rgba(227,230,255, 0.5);
  }

  .splitLayoutContainer {
    width: 100%;
    height: calc(100vh - 80px);
    padding: 95px 25px 25px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;

    .firstSplit {
      width: 65%;
    }

    .secondSplit {
      width: 35%;
    }

    .splitPaper {
      height: 100%;
      padding: 75px 25px 25px;
      border-radius: 0;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    }
  }
}

@media screen and (min-width: 600px) {
  .MainAdmin {
    height: calc(100% - 130px);
    top: 130px;
  }

  .BoxContainer {
    margin: 105px 0 25px 0;
  }
}

@media screen and (min-width: 1100px) {
  .MainAdmin {
    width: calc(100% - 260px);
    height: calc(100% - 80px);
    top: 80px;
  }

  .BoxContainer {
    width: calc(100% - 50px);
    margin: 95px 25px 25px 25px;
  }
}

@media screen and (min-width: 1536px) {
  .MainAdmin {
    width: calc(100% - 300px);
  }
}